/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'sass:map';
@use './m3-theme' as theme;

:root {
    color-scheme: light;
    @include mat.typography-hierarchy(mat.define-typography(map.get(theme.$theme-args, typography)));
    // The theme will be overwritten dynamically based on the currently active tenant. See ThemingService
    @include mat.theme(theme.$theme-args);
}

body {
    background: var(--mat-sys-surface);
    color: var(--mat-sys-on-surface);
}

html,
body {
    height: 100%;
    margin: 0;
}

.error-message {
    color: var(--mat-sys-error);
}
